import React, { useState, useEffect, useRef } from "react";

import { Formik } from "formik";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../Component/Header";
import HeaderNavbar from "../../Component/HeaderNavbar";
import PrimaryContactInformation from "../../Component/PrimaryContactInformation";
import LoginInput from "../../Component/LoginInput";
import EmailInput from "../../Component/EmailInput";
import BillingDetails from "../../Component/BillingDetails";
import creaditCardImage from "../../Assets/img/credit-card.png";
import TermsCheckbox from "../../Component/TermsCheckbox";
import lockImage from "../../Assets/img/lock.svg";
import logo from "../../Assets/img/logo.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { AffiliateUpgradeValidation } from "../../Component/Validation/AffiliateUpgradeValidation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../Stores/Actions/authActions";
import { affiliateActions } from "../../Stores/Actions/affiliateActions";
import SSNNumberModal from "./SSNNumberModal/SSNNumberModal";
import paypal from "../../Assets/img/checkout/paypal.png";
import applePay from "../../Assets/img/checkout/apple-pay.png";
import googlePay from "../../Assets/img/checkout/google-pay.png";
import wechatPay from "../../Assets/img/checkout/wechat-pay.png";
import bitcoin from "../../Assets/img/checkout/bitcoin.png";
import Icon from "../../Component/FormComponent/Icon";
import moment from "moment";
import { ErrorModel } from "../../Assets/scss/components/ErrorModal/ErrorModal";
import AffiliateTermsCheckbox from "../../Component/AffiliateTermsCheckbox";

function AffiliatePremiumMembership() {
  //features open and hide in planInfo
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [featureDropdown, setFeatureDropdown] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // billing details active tab
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const getAffiliateUserData = useSelector(
    (state) => state.affiliateReducer?.getAffiliateUserData
  );
  const affiliateUpgradeData = useSelector(
    (state) => state.affiliateReducer?.affiliateUpgradeData
  );
  const upgradeErrorMessage = useSelector(
    (state) => state.affiliateReducer?.upgradeErrorMessage
  );
  const base_currency = useSelector(
    (state) => state.currencyLanguageReducer?.data?.currency?.isoCode
  );
  const [memberIdError, setMemberIdError] = useState(null);

  const [isPromoAppliedMsg, setPromoAppliedMsg] = useState(null);
  const [finalCheckoutPrice, setFinalCheckoutPrice] = useState("199.00");

  const handleErrorModal = (status) => setOpenErrorModal(status);

  //function for handling dropdown feature

  const handleDropdown = () => setFeatureDropdown(!featureDropdown);

  //Initial Vlaues for input fields
  const intialValues = {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    nationality: "",
    countryRegion: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
    paypalEmail: "",
    confirmPaypalEmail: "",
    terms: false,
    affiliateTerms: false,
    memberId: "",
    promoCode: "",
    hasPromo: false,
    //cards
    cardHolderName: "",
    credit_number: "",
    security_code: "",
    mmyy: "",
    // ssnModal
    ssnNumber: "",
    confirmSsnNumber: "",
    einNumber: "",
    confirmEinNumber: "",
    stateAvailable: false,
  };

  useEffect(() => {
    if (getAffiliateUserData?.status == 200) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        title: getAffiliateUserData?.data?.title || "",
        firstName: getAffiliateUserData?.data?.first_name || "",
        lastName: getAffiliateUserData?.data?.last_name || "",
        email: getAffiliateUserData?.data?.email || "",
        phoneNumber: getAffiliateUserData?.data?.phone || "",
        nationality: getAffiliateUserData?.data?.country || "",
        countryRegion: getAffiliateUserData?.data?.country || "",
        ssnNumber: getAffiliateUserData?.data?.ssn || "",
        confirmSsnNumber: getAffiliateUserData?.data?.ssn || "",
        einNumber: getAffiliateUserData?.data?.ein || "",
        confirmEinNumber: getAffiliateUserData?.data?.ein || "",
        addressOne:
          getAffiliateUserData?.data?.user_address?.address_line_1 || "",
        addressTwo:
          getAffiliateUserData?.data?.user_address?.address_line_2 || "",
        city: getAffiliateUserData?.data?.user_address?.city || "",
        state: getAffiliateUserData?.data?.user_address?.state || "",
        zipCode: getAffiliateUserData?.data?.user_address?.zip || "",
      });
      const { data } = getAffiliateUserData;
      if (data) {
        if (data.country === "US" && !data.ssn && !data.ein) {
          setShowSSNModal(true);
        }
      }
    }
  }, [getAffiliateUserData]);

  const AutoFill = (memberId) => {
    dispatch(affiliateActions.getUserByMemberIdAction(memberId.trim()));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      setMemberIdError(null);
    };
  }, []);
  useEffect(() => {
    if (affiliateUpgradeData) {
      navigate("/thankyou/affiliate-upgrade");
    }
  }, [affiliateUpgradeData]);

  useEffect(() => {
    if (upgradeErrorMessage) {
      handleErrorModal(true); // open error modal
    }
  }, [upgradeErrorMessage]);

  //Title options list
  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Neutral.", label: "Neutral." },
  ];

  //handle numberCustomChange function
  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === "phoneNumber" ||
      field === "credit_number" ||
      field === "mmyy" ||
      field === "security_code"
    ) {
      if (Number(value) || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const [showSSNModal, setShowSSNModal] = useState(false);

  //handleCustomChagne funciton
  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
    if (field === "nationality") {
      if (value === "US") {
        setShowSSNModal(true);
      }
    }
  };

  const closeHandler = (formikProps, cancel) => {
    if (cancel) {
      formikProps.setFieldValue("nationality", "");
      setShowSSNModal(false);
    } else {
      formikProps.setFieldTouched("ssnNumber", true);
      formikProps.setFieldTouched("confirmSsnNumber", true);
      formikProps.setFieldTouched("einNumber", true);
      formikProps.setFieldTouched("confirmEinNumber", true);
      const { errors } = formikProps;
      if (
        errors.hasOwnProperty("ssnNumber") ||
        errors.hasOwnProperty("confirmSsnNumber") ||
        errors.hasOwnProperty("einNumber") ||
        errors.hasOwnProperty("confirmEinNumber")
      ) {
        return;
      } else {
        setShowSSNModal(false);
      }
    }
  };

  //handleSubmit furnciton
  const handleSubmit = (values, formikProps) => {
    if (values?.hasPromo && !isPromoAppliedMsg) {
      formikRef.current.setErrors({
        ...formikRef.current.errors,
        promoCode: "Invalid Promo Code",
      });
      formikRef.current.setTouched({ promoCode: true });
      return;
    }

    if (getAffiliateUserData.status == 200) {
      // status code 422 : email is not exist in database.
      const payload = {
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        login_name: values.email,
        email: values.email,
        country: values.nationality,
        phone: values.phoneNumber,
        address: values.addressOne,
        showCouponCode: values.hasPromo,
        base_currency: base_currency || "USD",
        status: "1",
        accessible_modules: "WALLET,CARDS,USERS",
        user_type_id:
          process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID || "",
        tenant_id: process.env.REACT_APP_TENANT_ID,
        timezone: "Australia/Melbourne",
        //card
        billing_address: `${values.addressOne} ${values.addressTwo}`,
        billing_city: values.city,
        billing_country: values.nationality,
        billing_state: values.state,
        billing_zip: values.zipCode,

        member_id: values.memberId,
      };
      if (!values.hasPromo) {
        (payload.security_code = values.security_code),
          (payload.credit_number = values.credit_number || ""),
          (payload.exp_month = values.mmyy.split("/")[0]),
          (payload.exp_year =
            moment().format("YYYY").slice(0, 2) + values.mmyy.split("/")[1]);
      }
      if (values.nationality === "US") {
        if (values.ssnNumber) {
          payload.ssn = values.ssnNumber;
        } else if (values.einNumber) {
          payload.ein = values.einNumber;
        }
      }
      if (isPromoAppliedMsg && values.hasPromo) {
        payload.promotional_code = values.promoCode;
      }
      if (values.couponCode) {
        payload.coupon_code = values.couponCode.trim();
      }
      dispatch(affiliateActions.upgradeAffiliateMembershipRequest(payload)); // email is unique, call signup api
    }
  };

  //info List
  const infoList = [
    "Access to Tripello Network Program with Unique and Comprehensive Compensation Plan",
    "Access to Personal Management Dashboard",
    "Access to Personal Invitation Links and QR Codes for Member and Affiliate Invites",
    "Access to Marketing Center with Social Media optimized Collateral and Tips and Tricks for Campaigns",
    "Access to Personal Wallet for tracking of Earnings and Payouts",
  ];
  // Fixed Header
  const [isHeaderSticky, setHeaderSticky] = useState(false);

  const onScrollFrame = (e) => {
    if (e.scrollTop > 10) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };
  // Fixed Header END

  const ApplyPromo = (promoCode) => {
    const payload = {
      code: promoCode.trim(),
      user_type_id: process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID,
    };

    dispatch(authActions.validatePromocodeRequest(payload)).then((res) => {
      if (res?.data?.success) {
        setPromoAppliedMsg(res?.data?.message);
        setFinalCheckoutPrice("0.00");
      } else {
        setFinalCheckoutPrice("199.00");
        setPromoAppliedMsg(null);
        formikRef.current.setErrors({
          ...formikRef.current.errors,
          promoCode: promoCode
            ? "Invalid Promo Code"
            : "Promo code can not be empty",
        });
        formikRef.current.setTouched({ promoCode: true });
      }
    });
  };

  //form being rendered here
  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
      formikProps;

    const handleCheckboxChange = (formikProps, field, value) => {
      if (field === "hasPromo") {
        formikProps.setFieldValue("hasCoupon", false);
        formikProps.setFieldValue(field, value);
      } else {
        formikProps.setFieldValue("hasPromo", false);
        formikProps.setFieldValue(field, value);
      }
      setFinalCheckoutPrice("199.00");
      formikProps.setFieldValue("couponCode", "");
      formikProps.setFieldValue("promoCode", "");
      formikProps.setErrors({
        ...formikProps.errors,
        couponCode: null,
        promoCode: null,
      });
      setPromoAppliedMsg(null);
      formikProps.setTouched({ couponCode: false });
      formikProps.setTouched({ promoCode: false });
    };
    return (
      <form onSubmit={handleSubmit}>
        {/* nationality = "us" && ssnDetails = null */}
        {showSSNModal && (
          <SSNNumberModal
            closeHandler={closeHandler}
            formikProps={formikProps}
          />
        )}
        {/* primary contact information */}
        <PrimaryContactInformation
          isUpgrade={true}
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          numberCustomChange={numberCustomChange}
          AutoFill={AutoFill}
          memberIdPlaceholder={"Your Premium Plus Member Number"}
          //Title
          title="title"
          titleLabel="Title"
          titleOptions={optionsTitle}
          titlePlaceholder="Title"
          // First Name
          firstName="firstName"
          firstNameLabel="First Name"
          firstNamePlaceholder="Walter"
          //Last Name
          lastName="lastName"
          lastNameLabel="Last Name"
          lastNamePlaceholder="White"
          //Email
          email="email"
          emailLabel="Email"
          emailPlaceholder="Email"
          countryCodeLabel="Country Code"
          //Phone Number
          phoneNumber="phoneNumber"
          phoneNumberLabel="Phone Number"
          //Nationality
          nationality="nationality"
          nationalityLabel="Nationality"
          nationalityPlaceholder="Select Country"
          //country region
          countryRegion="countryRegion"
          countryRegionLabel="Country Region"
          countryRegionPlaceholder="Select Country Region"
          //Addressone
          addressOne="addressOne"
          addressOneLabel="Address Line 1"
          addressOnePlaceholder="Address Line 1"
          //AddressTwo
          addressTwo="addressTwo"
          addressTwoLabel="Address Line 2"
          addressTwoPlaceholder="Address Line 2"
          //city
          city="city"
          cityLabel="City"
          cityPlaceholder="City"
          //state
          state="state"
          stateLabel="State/Province/Region"
          statePlaceholder="Select State"
          //zipcode
          zipCode="zipCode"
          zipCodeLabel="ZIP/Postal Code"
          zipCodePlaceholder="ZIP/Postal Code"
          // promoCode
          promoCode="promoCode"
          promoCodeLabel="Promo / Giftcard Code"
          promoCodePlaceholder="Enter Code"
          ApplyPromo={ApplyPromo}
          isPromoAppliedMsg={isPromoAppliedMsg}
          onChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          hasPromo={values?.hasPromo}
          showPromo={true}
        />
        {/* login input field  */}
        {/* <LoginInput
          formikProps={formikProps}
          //password
          password="password"
          passwordPlaceholder="Password"
          label="Password"
          //Confirm Password
          confirmPassword="confirmPassword"
          confirmPasswordPlaceholder="Confirm Password"
        /> */}
        {/* email input field */}

        <EmailInput
          formikProps={formikProps}
          //Email
          paypalEmail="paypalEmail"
          paypalEmailLabel="Email Address"
          paypalEmailPlaceholder="Email"
          //Confirm Email
          confirmPaypalEmail="confirmPaypalEmail"
          confirmPaypalEmailPlaceholder="Confirm Email"
          confirmPaypalEmaillLabel="Confirm Email Address"
        />

        {/* Billing Details */}

        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          {!values.hasPromo && (
            <div className="billingDetailsToggle">
              <ul>
                <li onClick={() => setActiveTab(1)}>
                  <a className={activeTab == 1 && "active"}>
                    Pay Using Debit / Credit Cards
                  </a>
                </li>
                <li onClick={() => setActiveTab(2)}>
                  <a className={activeTab == 2 && "active"}>
                    Pay Using Other Options
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${
              activeTab === 1 ? "activeTab" : ""
            }`}
          >
            {!values.hasPromo && (
              <BillingDetails
                formikProps={formikProps}
                numberCustomChange={numberCustomChange}
                billingDetails={
                  <div className="sectionTitle">
                    <h2>Billing Details</h2>
                  </div>
                }
                // Card Holder Name
                cardHolderName="cardHolderName"
                cardHolderNameLabel="Card Holder Name"
                cardHolderNamePlaceholder="Card Holder Name"
                // Card Number
                cardHeadingLabel="Enter Card Details"
                credit_number="credit_number"
                cardImage={creaditCardImage}
                cardNumberPlaceholder="Card"
                // mmyy
                mmyy="mmyy"
                mmyyPlaceholder="MM/YY"
                // cvv
                security_code="security_code"
                cvvPlaceholder="CVV"
                AutoFill={AutoFill}
              />
            )}
            <div>
              <div className="d-flex checkBoxWrap">
                <div>
                  <TermsCheckbox
                    name="terms"
                    id="terms"
                    value={values.terms}
                    onChange={(e) => {
                      handleCustomChange(
                        formikProps,
                        "terms",
                        e.target.checked
                      );
                    }}
                    error={errors.terms}
                    touched={touched.terms}
                    onBlur={handleBlur}
                  />
                  <AffiliateTermsCheckbox
                    name="affiliateTerms"
                    id="affiliateTerms"
                    value={values.affiliateTerms}
                    onChange={(e) => {
                      handleCustomChange(
                        formikProps,
                        "affiliateTerms",
                        e.target.checked
                      );
                    }}
                    onBlur={handleBlur}
                    error={errors.affiliateTerms}
                    touched={touched.affiliateTerms}
                  />
                </div>
                <div className="buttonWrap">
                  <div className="desktopViewDisplayNone">
                    <h4>
                      <strong>$199.00</strong>/ First Year
                    </h4>
                    <h4>(Renews As Affiliate Membership)</h4>
                  </div>
                  <button className="btn" type="submit" id="sbumit-button">
                    <img src={lockImage} /> Pay Now
                  </button>
                </div>
              </div>
              {/* <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    <strong>$24.95</strong>/ per month
                  </h4>
                  <h4>($299.40 total at checkout)</h4>
                </div>
                <button className="btn" type="submit" id="sbumit-button">
                  <img src={lockImage} /> Pay Now
                </button>
              </div> */}
            </div>
          </div>

          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${
              activeTab === 2 ? "activeTab" : ""
            }`}
          >
            <div className="walletOptionWrap">
              {!values.hasPromo && (
                <div className="walletWrapper wallet-options-same-height">
                  <div>
                    <ul>
                      {/* <li>
                      <div className="checkBoxContent">
                        <label
                          for="paypal-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="paypal-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={paypal} alt="wallet icon" />
                            </div>
                            <h6>PayPal</h6>
                          </div>
                        </label>
                      </div>
                    </li> */}
                      <li>
                        <div className="checkBoxContent">
                          <label
                            for="apple-id"
                            className="customCheckbox checkbox-label"
                          >
                            <input
                              type="radio"
                              id="apple-id"
                              name="WalletPayment"
                            />
                            <span></span>
                            <div className="wallet-icon-wrap">
                              <div className="wallet-icon">
                                <img src={applePay} alt="wallet icon" />
                              </div>
                              <h6>Apple Pay</h6>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkBoxContent">
                          <label
                            for="Google-id"
                            className="customCheckbox checkbox-label"
                          >
                            <input
                              type="radio"
                              id="Google-id"
                              name="WalletPayment"
                            />
                            <span></span>
                            <div className="wallet-icon-wrap">
                              <div className="wallet-icon">
                                <img src={googlePay} alt="wallet icon" />
                              </div>
                              <h6>Google Pay</h6>
                            </div>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="dflex checkBoxWrap">
                <div>
                  <TermsCheckbox
                    name="terms"
                    id="terms"
                    value={values.terms}
                    onChange={(e) => {
                      handleCustomChange(
                        formikProps,
                        "terms",
                        e.target.checked
                      );
                    }}
                    onBlur={handleBlur}
                    error={errors.terms}
                    touched={touched.terms}
                  />
                  <AffiliateTermsCheckbox
                    name="affiliateTerms"
                    id="affiliateTerms"
                    value={values.affiliateTerms}
                    onChange={(e) => {
                      handleCustomChange(
                        formikProps,
                        "affiliateTerms",
                        e.target.checked
                      );
                    }}
                    onBlur={handleBlur}
                    error={errors.affiliateTerms}
                    touched={touched.affiliateTerms}
                  />
                </div>
                <div className="buttonWrap">
                  <div className="desktopViewDisplayNone">
                    <h4>
                      Amount Due Today:{" "}
                      <strong>&nbsp;${finalCheckoutPrice}</strong>
                    </h4>
                  </div>

                  <button type="submit" className="walletPayNow btn d-block">
                    <img src={lockImage} />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <div
      className={
        isHeaderSticky
          ? "siteBg checkoutPageWrap MembershipCheckoutPageWrap affiliateCheckoutPageWrap affiliateUpgradeCheckoutPageWrap header-sticky"
          : "siteBg checkoutPageWrap MembershipCheckoutPageWrap affiliateCheckoutPageWrap affiliateUpgradeCheckoutPageWrap"
      }
    >
      <div className="pageWrap pageWrapInner">
        {/*<div className="fixedHeader">
  <HeaderNavbar /> 
        </div>*/}
        <div className="fixedHeader">
          <Header headerName={true} />
        </div>
        <section className="mainContent">
          <Scrollbars
            // autoHeight={`calc(100vh - 120px)`}
            onScrollFrame={onScrollFrame}
            autoHeight={true}
            autoHeightMin={110}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container pageInternalScrollTopPadding">
              <div className="checkoutWrapper">
                <div className="row">
                  <div className="col leftContent">
                    <Formik
                      innerRef={formikRef}
                      onSubmit={handleSubmit}
                      initialValues={intialValues}
                      validationSchema={AffiliateUpgradeValidation}
                      validateOnBlur={false}
                      enableReinitialize={true}
                    >
                      {renderForm}
                    </Formik>
                  </div>
                  <div className="col rightContent">
                    <div className="infoBox">
                      <div className="membership-content">
                        <img src={logo} alt="" />
                        <div className="planName">
                          Upgrade to Network Affiliate Membership
                        </div>
                        <p>(12 Month)</p>
                      </div>
                    </div>
                    <div
                      className={
                        !featureDropdown ? "features" : "features open"
                      }
                    >
                      {/* Plan Info list */}
                      <ul>
                        {infoList.map((list) => {
                          return <li key={list}>{list}</li>;
                        })}
                      </ul>
                      <div className="priceInfo">
                        <h6>
                          <strong>${finalCheckoutPrice}</strong>/ First Year
                        </h6>
                        <h6>(Renews As Network Affiliate Membership)</h6>
                      </div>
                    </div>
                    <button
                      onClick={handleDropdown}
                      className={
                        !featureDropdown
                          ? "desktopViewDisplayNone toggleButtons"
                          : "desktopViewDisplayNone toggleButtons open"
                      }
                    >
                      <span className="show-features">Show Features</span>
                      <span className="hide-features">Hide Features</span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      <ErrorModel
        openErrorModal={openErrorModal}
        handleErrorModal={handleErrorModal}
        message={upgradeErrorMessage}
      />
    </div>
  );
}

export default AffiliatePremiumMembership;
